
            @import 'src/styles/mixins.scss';
        
.mainContainer {
    position: relative;
    margin-top: 60px;
    @include lg {
        margin-top: 120px;
    }
    &::before {
        content: "";
        left: 50%;
        width: 100vw;
        transform: translateX(-50%);
        height: 100%;
        position: absolute;
        z-index: -2;
        background: linear-gradient(
            180deg,
            #edf6ff -17.83%,
            rgba(255, 255, 255, 0) 73.32%
        );
    }
}

.oneBoxStyle {
    border-radius: 25px;
    background: #fff;
    box-shadow: 0px 4px 17px 0px rgba(0, 0, 0, 0.15);
    padding: 34px 20px 0 20px;
    width: 518px;
    height: 404px;
    @include sm {
        padding: 74px 34px 0 34px;
        width: 518px;
        height: 404px;
        margin: 0 auto;
    }
    @include lg {
        margin: unset;
    }
}
