
            @import 'src/styles/mixins.scss';
        
.mainContainer {
    position: relative;
    margin-top: 150px;
    @include lg {
        margin-top: 315px;
    }
    &::before {
        content: "";
        left: 50%;
        width: 100vw;
        transform: translateX(-50%);
        height: 100%;
        position: absolute;
        z-index: -2;
        background: linear-gradient(
            180deg,
            #edf6ff -17.83%,
            rgba(255, 255, 255, 0) 73.32%
        );
    }
}
.boxStyle {
    border-radius: 25px;
    background: #fff;
    box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.15);
    padding: 47px 20px;
    position: relative;

    top: -90px;
    @include sm {
        padding: 97px 50px;
        top: -120px;
    }
    @include lg {
        top: -170px;
    }
}
